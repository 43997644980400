$(document).ready(function() {
	/*
	 *  Simple image gallery. Uses default settings
	 */

	$('.fancybox')
		.attr('rel', 'media-gallery')
		.fancybox(
			{
				centerOnScroll : true,
				openEffect : 'none',
				closeEffect : 'none',
				prevEffect	: 'elastic',
				nextEffect	: 'elastic',

				//arrows : false, //стрелочки
				helpers : {
					buttons : {},
					thumbs	: {
						width	: 80,
						height	: 50
					},
					title : {
						type : 'inside'
					},				
				}
			});
	/*
	 *  Media helper. Group items, disable animations, hide arrows, enable media and button helpers.
	*/
	$('.fancybox-media')
		.attr('rel', 'media-gallery')
		.fancybox({
			centerOnScroll : true,
			openEffect : 'none',
			closeEffect : 'none',
			prevEffect	: 'elastic',
			nextEffect	: 'elastic',

			//arrows : false, //стрелочки
			helpers : {
				media : {},
				buttons : {},
				thumbs	: {
					width	: 80,
					height	: 50
				},
				title : {
					type : 'inside'
				},				
			}
		});

	
	$('.fancybox-ajax').click(function(e) {
		e.preventDefault();
		var el = $(this);
	    var href = el.data("href");
	    
	    if(href){
			$.fancybox.open(
			{
				centerOnScroll : true,
				href: href,
		        type: "ajax",
			});
	    }
	});
	
	$('.fancybox-inline')
		.fancybox({
			centerOnScroll : true,	
			type: "inline",
			padding:0,
			height:'auto',
		});
});