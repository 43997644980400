$(document).ready(function(){
	
		var timerFilters;
	
		filter = {
	
			'mouse_slide' : function (el){

				$(".filters_ok_abs").css({ 'display' : 'block' });
				clearTimeout(timerFilters);
				
				var topHeight = el.offsetTop;

				if($(el).attr("type") == 'checkbox'){
					topHeight = $(el).closest("label")[0].offsetTop;
				}
				
				if($(el).closest(".date-picker").html() != undefined){
					topHeight = $(el).closest(".date-picker")[0].offsetTop;
				}
				
				if($(el).hasClass("filter-participants")){
					topHeight = $(el).closest(".form-group")[0].offsetTop;
				}

				$(".filters_ok_abs").css({"top" : topHeight-5 + 'px'});
				
				timerFilters = setTimeout(function() {
					$(".filters_ok_abs").css({ 'display' : 'none' })
				}, 5000);
			}
			
		}
		
		$(".filter-participants").select2().on("change", function(e) {
			console.log(this);
			filter.mouse_slide(this);
	    });
		
		$(".filter-form input").click(function(){
			filter.mouse_slide(this);
		});
		
		$(".filter-submit").click(function(){
			filterSubmit(this);
		});
		
		$('.date-filter').datepicker({
		    autoclose: true,
		    dateFormat: "yy.mm.dd",
		});
		
		function filterSubmit(el){
			//select фильтры
			var selectFilter = $(".filter-select");

			var aData = {}, res = [];
        	
        	//Чекбокс фильтры
        	var checkFilter = $(".filter-form input[type=checkbox]:checked");

			//Формируем массив выбранных чекбоксов
        	$.each(checkFilter, function(index, value){
				id = $(value).attr("id");
				//Массив с атрибутом и значением
				var check_data = id.split('-');
				attr = check_data[0];
				attr_value = check_data[1];
				
				if(typeof aData[attr] == 'undefined')aData[attr] = [];
				
				if($.isNumeric(attr_value)){
					aData[attr].push(attr_value);
				}
				
        	});
        	
        	var minPrice = $("#min-price").val();
        	if(minPrice != ''){
        		if(typeof aData['min-price'] == 'undefined')aData['min-price'] = [];
        		aData['min-price'].push(minPrice);
        	}
        	
        	var maxPrice = $("#max-price").val();
        	if(maxPrice != ''){
        		if(typeof aData['max-price'] == 'undefined')aData['max-price'] = [];
        		aData['max-price'].push(maxPrice);
        	}
        	
        	var participants = $("#participants").val();
        	if(participants != ''){
        		if(typeof aData['participants'] == 'undefined')aData['participants'] = [];
        		aData['participants'].push(participants);
        	}
        	
        	var dateStart = $("#min-date-start").val();
        	if(dateStart != ''){
        		if(typeof aData['min-date-start'] == 'undefined')aData['min-date-start'] = [];
        		aData['min-date-start'].push(dateStart.replace(/\//, "-"));
        	}
        	
        	var dateEnd = $("#max-date-start").val();
        	if(dateEnd != ''){
        		if(typeof aData['max-date-start'] == 'undefined')aData['max-date-start'] = [];
        		aData['max-date-start'].push(dateEnd.replace(/\//, "-"));
        	}
        	
        	//Формируем массив url из фильтра
        	$.each(aData, function( index, value ) {
        		res.push(index+"="+value.join("-"));
        	});
        	
        	action = 'product';
        	
        	res = res.join(";");
        	if(res != '')res = '/'+res; 

        	window.location.href = $(el).closest("form").attr("action")+res;
			
		}
		
		$(".button-search").click(function(){
			var value = $(".input-search").val();
			if(value != ''){
				window.location = '/product?search='+value;
			}
		});
		
});