function Shop(){
	this.email_add=function(data){
		$.ajax({
	           type: "POST",
	           url: '/account/email-add',
	           data: data, // serializes the form's elements.
	           dataType: 'json',
	           success: function(data)
	           {
	        	   if (data['message']){
	        		   Core._processingData(data);
	        	   }else{
	        		   if(data['status'] == 'success'){
	        			   $('#block_email_add').toggle('normal');
	        			   $('#block_email_add').html('<p class="text-success">Email успешно добавлен </p>'); 
	        			   $('#block_email_add').toggle('normal');
	        		   }
	        	   }
	           }
	         });
		return false;
	};
	this.check_phone=function(phone){
		if(phone=='')return;
		$.ajax({
			   type: "POST",
			   url: "/cart/check-phone",
			   data: "phone="+phone,
			   dataType: "json",
			   success: function(data){
				   if(data.login=='true'){
					   $.fancybox.open(
						{
							centerOnScroll : true,
							href: '/cart/request-login',
					        type: "ajax",
					        ajax: {
					            type: "POST",
					            data: {
					            	phone: phone
					            }
					        }
						});
				   }					   
			   }
		});
	}

}

var shop=new Shop();

$(document).ready(function(){
	$.ajax({
		type: "POST",
		url: '/auth/buttons',
		data: {popup:'get'},
		dataType: "html",
		success: function(data){
			$('#login-buttons').html(data);
		}
	});
	(function($) {
	    $.fn.toggleDisabled = function(){
	        return this.each(function(){
	            this.disabled = !this.disabled;
	        });
	    };
	})(jQuery);
	
	$('#frm_email_add').submit(function(e){
		e.preventDefault();
		var data = $(this).serialize();
		if(data){
			shop.email_add(data);
		}
	});

	$('#id_input_user_phone').focusout(function(e){
		if($(this).hasClass('check-phone')){
			shop.check_phone($(this).val());
		}
	});
	$('#show_login_popup').click(function(e){
		$.fancybox.open(
				{
					centerOnScroll : true,
					href: '/cart/request-login',
			        type: "ajax",
			        ajax: {
			            type: "POST",
			            data: {
			            	
			            }
			        }
				});
	});
	
	$('#show_login').click(function(e){
		$.fancybox.open(
				{
					centerOnScroll : true,
					href: '/cart/request-login',
			        type: "ajax",
			        ajax: {
			            type: "POST",
			            data: {
			            	comments:1
			            }
						
			        }
				});
		
	});
	$('#show_register_popup').click(function(e){
		$.fancybox.open(
				{
					centerOnScroll : true,
					href: '/cart/register',
			        type: "ajax",
			        ajax: {
			            type: "POST",
			            data: {
			            	register:'register'
			            }
			        }
				});
	});
	$('#show_callback_popup').click(function(e){
		$.fancybox.open(
				{
					centerOnScroll : true,
					href: '/feedback',
			        type: "ajax",
			        ajax: {
			            type: "POST",
			            data: {
			            }
			        }
				});
	});
	
    $('#show_remind').click(function(e){
		$.fancybox.open(
				{
					centerOnScroll : true,
					href: '/auth/remind-popup',
			        type: "ajax",
			        ajax: {
			            type: "POST",
			            data: {
			            	remind:'remind'
			            }
			        }
				});
	});
	
	$('#other_recipient').click(function(e){
		$('#other_recipient_info').toggle( "normal", function() {
			$('#other_recipient_info input').toggleDisabled()
		});
	});
	
	$('.delete-social-link').click(function(e){
		e.preventDefault();
		var href = $(this).data('href');

        bootbox.confirm("Вы уверены, что хотиете отвязать социальную сеть?", function(result) {
           if(result)
        	   location.href = href;
        }); 
    });
});

function loginSocial(val){
	if(val=='facebook-auth')
		$(".facebook-auth").click();
	else
		location.href= val;
}

