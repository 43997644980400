function lookup(inputString) {
	if(inputString.length == 0) {
		$('#suggestions').fadeOut();
	} else {
		$.get("/product/search-inline", {search: ""+inputString+""}, function(data) {
			$('#suggestions').fadeIn(); 
			$('#suggestions').html(data); 
		});
	}
}

$(".input-search").keypress(function(e){
	if(e.keyCode==13){
		var value = $(this).val();
		if(value != ''){
			window.location = '/product?search='+value;
		}
	}
});

