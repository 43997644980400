var Core = {
		
		init: function(){
			 //Инициализация сообщений
			 toastr.options = {
	                    closeButton: true,
	                    timeOut: 5000,
	                };
			 //Сбросс сообщений валидации при клике на поле
			 $("input").click(function(){
				 Core._resetValidation(this);
			 });
			 $("select").change(function(){
				 Core._resetValidation(this);
			 });
		},

		customerAjax: function(url, data, redirect, script){
			if(data.length>0){
				data = data.join('&');
			}else{
				data = '';
			}
			$.ajax({
				   type: "GET",
				   url: url,
				   data: data,
				   dataType: "json",
				   success: function(data){
					   Core._processingData(data);
					   if(typeof redirect != 'undefined' && redirect != '')window.location = redirect;
					   if(typeof script != 'undefined' && script != '')eval(script);
				   }
			});
		},
		//Ajax сабмит формы
		submitForm: function(form){
			
			//Если форма сабмитится, сбрасываем скрипт form.js
			$(window).off('beforeunload');
			//Выключаем обычный обработчик
			$(form).submit(function(e){e.preventDefault();});
			
			$(form).find(".ckeditor").each(function() { 
				var editorName = $(this).attr("id");
				$(this).val(CKEDITOR.instances[editorName].getData());
			}); 
			
			$(form).ajaxSubmit({
				dataType:'json',
				success: function(data){
					Core._processingData(data);
				}
	        });
	        
			return false;
		},
		//Обработка полученных данных
		_processingData: function(data){
			
			for(i in data){
				   if(i == 'script')
					   eval(data[i]);
				   if(i == 'redirect'){
					   window.location = data[i];
				   }
				   if(i == 'innerHTML')
					   document.getElementById(data['innerHTML'][0]).innerHTML = data['innerHTML'][1];
				   if(i == 'message'){
					   if(typeof data['message'] != 'object'){
						   typeof data['title'] == 'undefined' ? title = '' : title = data['title'];
						   typeof data['type'] == 'undefined' ? type = '' : type = data['type'];
						   Core.showToastMessage(data['message'], title, type);
					   }else{
						   Core.showValidationMessage(data['message']);
					   }
				   }   
			}
		},
		//Вывод тостов
		showToastMessage: function(message, title, type){
			 types = ['success', 'error', 'info', 'warning'];
			 if(typeof type == 'undefined' || type == '')type = 'success';
			 if(typeof title == 'undefined')title = '';
			 
			 if($.inArray( type, types) != -1)
				 window['toastr'][type](message, title);
		},
		//Сообщения валидации
		showValidationMessage: function(message){
			//Флаг принимает true если были сброшены сообщения валидации
			var is_reset = false;
			
			//Сбрасываем сообщения валидации для текущей формы
			for (i in message){			
				
				var form = $($("#id_message_"+i)).closest('form');
				
				$(form).find(".form-group").each(function() { 
					$(this).removeClass("has-error"); 
				});
				
				$(form).find(".help-block.validation").each(function() { 
					$(this).html(""); 
				});
				
				is_reset = true;
				break;
			}
			
			//Если сообщения не были сброшены, значит мы получили параметры, которы не существует
			//или они находятся не в форме
			//в таком случае сбрасываем все сообщения валидации в окне
			if(!is_reset){
				$(document).find(".form-group").each(function() { 
					$(this).removeClass("has-error"); 
				}); 
				$(document).find(".help-block.validation").each(function() { 
					$(this).html(""); 
				});
				is_reset = true;
			}
			
			//Добавляем сообщения валидации
			for (i in message){
				//проверяем input:text 
				$(form).find("#id_input_"+i+":text").each(function() {
					var value = $.trim( $(this).val() );//убираем проблелы до и после значения
		            $(this).val( value );
				});
				$("#id_message_"+i).html(message[i]);
				$($("#id_message_"+i).closest(".form-group")).addClass("has-error");
			}
			var search_element = $('.has-error').first();
			if(search_element.length > 0 && !search_element.hasClass('modal-form')) {
				$('body,html').animate({scrollTop:search_element.offset().top-10},500); 	       
		    }
		},
		//Сбросс сообщений валидации для выбранного элемента
		_resetValidation: function(el){
			try{
				var id = $(el).attr("id");
				id = id.replace(/id_input_/, "");
				$("#id_message_"+id).html("");
			} catch(e) {}
			$($(el).closest(".form-group")).removeClass("has-error");
		},
		//Метод , с помощью которого запрос на сервер обрабатывается через подтверждение
		//data - массив параметров для отправки (["test=1", "test2=2"])
		_actionConfirm: function(url, data_array, script){	
			$.ajax({
			    url : url,
			    type: "GET",
			    data : data_array,
			    dataType: "json",
			    success: function(data, textStatus, jqXHR)
			    {
		 		    if(data['processor'] == 'confirm'){
		 		   		var message;
		 				if(data['message']) message = data['message']; else message = 'Are you sure you want to confirm the action';
		 		   	 	bootbox.confirm(__(message)+"?", function(result) {
		 			     	if(result){
		 	 			     	var requestData = ['confirm=true'];
		 	 			     	for (i in data_array){
		 	 			     		requestData.push(i+'='+data_array[i]);
		 	 			     	}
		 			     		Core.customerAjax(url,requestData, '', script);
		 			     	}
		 		 		}); 
		 		    }else{
		 		    	Core._processingData(data);		 		    	
					}
			    }
			});
		},
		//Метод по имени получает значение из $_GET массива
		getParameterByName: function(name) {
		    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		        results = regex.exec(location.search);
		    return results === null ? "undefined" : decodeURIComponent(results[1].replace(/\+/g, " "));
		},
		isMultiLang: function(){
			return $('body').hasClass("multilang");
		},
		getLang: function(){
			return $('html').attr("lang");
		}
}

Core.init();