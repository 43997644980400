function Cart(){
	this.update_state=function(){
		$.get('/cart/state', function(data){
			$("#cart-widget").html(data);
		});
		return false;
	};

	this.change_delivery=function(id_delivery){
		$.getJSON('/cart/change-delivery/'+id_delivery,
		function(data){	
			if(data.price>0){
				$("#delivery_cost").html("Стоимость доставки "+data.price+" руб.");
				$("#delivery_cost").show();
			}else{
				$("#delivery_cost").html("");
				$("#delivery_cost").hide();
			}
		});
		return false;
	};
	
	this.add=function(href, count, ticket_id){
		if(ticket_id)
			post = { count: count , ticket: ticket_id };
		else
			post = { count: count };
		
		$.fancybox.open(
		{
			centerOnScroll : true,

			href: href,
	        type: "ajax",
	        ajax: {
	            type: "POST",
	            data: post
	        }
		});
		cart.update_state();
		return false;
	};

	this.add_many=function(href,sector_id){
		var array_places =[];
		$('.sector-'+sector_id).each(function(){
			if($(this).hasClass('place-select')){
				array_places[array_places.length] = $(this).data('place-id');
			}

		});

		post = { tickets: array_places };


		$.fancybox.open(
			{
				centerOnScroll : true,

				href: href,
				type: "ajax",
				ajax: {
					type: "POST",
					data: post
				}
			});
		cart.update_state();
		return false;
	};
	
	this.fast_order = function(href,count,phone) {
		$.fancybox.open(
		{
			centerOnScroll : true,

			href: href,
	        type: "ajax",
	        ajax: {
	            type: "POST",
	            data: {
	            	count: count,
	            	phone: phone
	            }
	        }
		});

	}

	this.recalc=function(frm){
		$.ajax({
	           type: "POST",
	           url: '/cart/recalc',
	           data: frm.serialize(), // serializes the form's elements.
	           success: function(data)
	           {
	        	   if (data){
	        		   $(".goods-data").html(data);
	        		   Layout.initTouchspin();
	        		   cart.update_state();
	        	   }
	           }
	         });
		return false;
	};
	
	this.delete_item=function(href){
		Metronic.blockUI({
            target: '.goods-page',
            iconOnly: true,
        });
		$.ajax({
	           type: "GET",
	           url: href,
	           dataType:'json',
	           success: function(data)
	           {
	        	   cart.update_state();
	        	   Metronic.unblockUI('.goods-page');
	        	   if(data['empty']){
	        		   $(".goods-page").html(data['html']);
	        	   }else{
	        		   $(".goods-data").html(data['html']);
	        	   }
	        	   Layout.initTouchspin();
	           }
	         });
		return false;
	};

}

var cart=new Cart();

$(document).ready(function(){

	$.ajax({
		url : '/cart/widget',
		type: "GET",
		dataType: "html",
		success: function(data)
		{
			$('#cart-widget').html(data);
		}
	});

	$('#popup-fast-order').click(function(e){
		e.preventDefault();
		var phone = $('#id_input_phone').val();	
		var el = $('#popup-fast-order');	
		var product_id = el.data('product-id');
		var href = el.data('href');	

		if(href && product_id){		
			var link = href+'/'+product_id;
			cart.fast_order(link,$('#quantity_id').val(),phone);
		}
	});
	
	$('#popup-cart-add').click(function(e){
		e.preventDefault();
		var el = $('#popup-cart-add');	
		var product_id = el.data('product-id');
		var href = el.data('href');	
		
		if(href && product_id){
			var link = href+'/'+product_id;
			cart.add(link,$('#quantity_id').val());
		}
	});
	
	$('.popup-cart-add-ticket').click(function(e){
		e.preventDefault();
		var el = $(this);	
		var product_id = el.data('product-id');
		var ticket_id = el.data('ticket-id');
		var href = el.data('href');	
		var quantity = $('#product-'+product_id+'-ticket-'+ticket_id+'-quantity').val();
		if(href && product_id && ticket_id){
			var link = href+'/'+product_id;
			cart.add(link,quantity,ticket_id);
		}
	});

	$('.popup-cart-add-places').click(function(e){
		e.preventDefault();
		var el = $(this);
		var product_id = el.data('product-id');
		var sector_id = el.data('sector');
		var href = el.data('href');

		if(href && product_id && sector_id){
			var link = href+'/'+product_id;
			cart.add_many(link,sector_id);
		}
	});
	
	$('#id_input_delivery_method').change(function(e){
		cart.change_delivery($('#id_input_delivery_method').val());
	});
	
	$('.cart-submit').click(function(e){
 		$('#frm_cart').submit();
	});

	if(typeof $('#tickets-wrapper').html() !== 'undefined'){
		var hellopreloader = document.getElementById("hellopreloader_preload");
		function fadeOutnojquery(el) {
			el.style.opacity = 1;
			var interhellopreloader = setInterval(function () {
				el.style.opacity = el.style.opacity - 0.05;
				if (el.style.opacity <= 0.05) {
					clearInterval(interhellopreloader);
					hellopreloader.style.display = "none";
				}
			}, 16);
		}

		$.ajax({
			url : '/product/atickets',
			type: "GET",
			data : {id:$('#tickets-wrapper').data('id')},
			dataType: "html",
			success: function(data)
			{
				$('#tickets-wrapper').html(data);
				fadeOutnojquery(hellopreloader);
			}
		});
	}
});